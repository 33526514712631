import produce from 'immer';
import {
  LOGIN_USER,
  LOGOUT_USER,
  EDIT_USER
} from '../actions';

const INITIAL_STATE = {
  id: '',
  nome: '',
  email: '',
  token: '',
  isAuthenticated: false,
  url_imagem: ''
}

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_USER:
      return produce(state, draft => {
        draft.id = action.user.id;
        draft.nome = action.user.nome;
        draft.email = action.user.email;
        draft.token = action.user.token;
        draft.isAuthenticated = action.user.isAuthenticated;
        draft.url_imagem = action.user.url_imagem;
      });
    case LOGOUT_USER:
      return produce(state, draft => {
        draft.id = '';
        draft.nome = '';
        draft.email = '';
        draft.token = '';
        draft.isAuthenticated = false;
      });
    case EDIT_USER:
      return produce(state, draft => {
        draft.nome = action.user.nome;
        draft.email = action.user.email;
        draft.url_imagem = action.user.url_imagem;
        console.log(action.user)
      });
    default:
      return state;
  }
}
