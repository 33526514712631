import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store, persistor} from './redux/store';
import { PersistGate } from 'redux-persist/es/integration/react';


const App = React.lazy(() => import('./App' ));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
 serviceWorker.register();