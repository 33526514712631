import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import sagas from "./sagas";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Middleware: Redux Persist Config
const persistConfig = {
    key: 'root',
    storage
} 

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    persistedReducer, 
    applyMiddleware(sagaMiddleware)
);
const persistor = persistStore(store)

sagaMiddleware.run(sagas);

export { 
    store, 
    persistor 
};